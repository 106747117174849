import React, { useEffect } from "react"

import { useScrollAnim } from "src/components/hooks/hooks"
import { Button } from "components/anti/buttons/buttons"

export const Covid19Submit = ({ data, price }) => {
    const [trigger, anim] = useScrollAnim()

    const numberWithCommas = x => {
        return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    }

    // const getRectTop = (el) => {
    //     var rect = el?.getBoundingClientRect().top;
    //     return rect.top;
    // }

    const listenScrollEvent = () => {
        const priceFloat = document.querySelector("#price")
        const footer = document.querySelector("#footer")

        if (
            priceFloat?.getBoundingClientRect()?.top +
            document.body.scrollTop +
            priceFloat?.offsetHeight >=
            footer?.getBoundingClientRect()?.top + document.body.scrollTop - 10
        )
            priceFloat?.classList?.remove("price-fixed")
        if (
            document.body.scrollTop + window.innerHeight <
            footer?.getBoundingClientRect()?.top + document.body.scrollTop
        )
            priceFloat?.classList?.add("price-fixed")
    }

    useEffect(() => {
        window.addEventListener("scroll", listenScrollEvent)
    }, [])
    return (
        <section
            className="py-3 py-md-2 sc-wfb-price price-fixed"
            id="price"
            ref={trigger}
        >
            <div className="container">
                {/* Desktop */}
                <div className="row d-md-flex d-none">
                    <div className="col-md-5 mb-sm-down-3">
                        <small className={`${anim(1)}`}>{data.label}</small>
                        <p className={`h2 ${anim(2)} mb-0`}>
                            Rp{numberWithCommas(price)}
                        </p>
                    </div>
                    <div className="col-md-4 mb-sm-down-3">
                        <div className="d-flex align-items-center h-100">
                            {data.information.icon.sourceUrl && (
                                <img
                                    src={data.information.icon.sourceUrl}
                                    className={`img-fluid h-50px mr-3 ${anim(3)}`}
                                    alt="icon"
                                />
                            )}
                            <p className={`mb-0 ${anim(4)}`}>{data.information.text}</p>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div
                            className={`d-flex align-items-center h-100 float-right ${anim(
                                5
                            )}`}
                        >
                            <Button variant="secondary" type="submit">
                                {data.information.button.text}
                            </Button>
                        </div>
                    </div>
                </div>

                {/* Mobile */}
                <div className="row d-md-none d-flex">
                    <div className="col-8 col-md-5">
                        <small className={`${anim(1)}`}>{data.label}</small>
                        <p className={`h2 ${anim(2)} mb-0`}>
                            Rp{numberWithCommas(price)}
                        </p>
                    </div>
                    <div className="col-4">
                        <div className={`${anim(5)}`}>
                            <Button variant="secondary" type="submit">
                                {data.information.button.text}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
