import React, { useEffect } from "react"
import { useStaticQuery, graphql, navigate } from "gatsby"

import Layout from "components/layout"
import { Covid19TestCover } from "components/pages/covid-19-test/cover"
import { Covid19TestForm } from "components/pages/covid-19-test/form"
import { parseCookies, setCookie } from 'nookies'
import Seo from "components/seo"



const Covid19Test = ({ location }) => {
  const data = useStaticQuery(GET_COVID_19_TEST)
  const blocks = data.wordPress.page.covid19TestPage

  const cookies = parseCookies()

  useEffect(() => {
    const authToken = cookies.authToken
    if (!authToken) {
      navigate("/login")
      setCookie(null, 'loginDirect', "/covid-19-test")
    }
  }, [])

  return (
    <Layout>
      <Seo title="Covid-19 Test" />
      <Covid19TestCover data={blocks.cover} />
      <Covid19TestForm hash={location.hash} data={blocks.headline} priceLabel={blocks.priceLabel} />
    </Layout>
  )
}

export default Covid19Test

const GET_COVID_19_TEST = graphql`
query Covid19TestDetail {
    wordPress {
      page(id: "covid-19-test", idType: URI) {
        covid19TestPage {
          priceLabel {
            label
          }
          headline {
            title
            text
            formDescription
            fieldGroupName
            button {
              url
              text
            }
          }
          cover {
            image {
              sourceUrl
            }
          }
        }
      }
    }
  }
  
`