import React from "react"

import { Cover } from "components/anti/cover/cover"

// import covid19Cover from "assets/img/covid-19-test.jpg"

export const Covid19TestCover = ({ data }) => {
    return (
        <Cover
            variant="basic"
            theme="dark"
            img={data.image.sourceUrl}
            imgRatio="r-9-16"
            imgHeight="h-600px"
            className="sc-covid19Test-cover cover-full"
        />
    )
}