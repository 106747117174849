import React, { useState, useEffect } from "react"

import * as Yup from "yup"
import { Formik, Form } from "formik"
import moment from "moment"
import { useQuery, useMutation } from "@apollo/client"
import { parseCookies, setCookie } from 'nookies'
import { navigate } from "gatsby"

import { Button } from "components/anti/buttons/buttons"
import { DatePicker, Select, Input } from "components/anti/forms/forms"
import { Covid19Submit } from "components/pages/covid-19-test/form-button"
import { Modal } from "components/anti/modal/modal"
import { NumFormat } from "components/anti/num-format/num-format"

import { getHealthPCRQuery, getProvincesQuery, manualPaymentQuery, newTestPCRQuery } from "components/utils/streamworx"

import icSuccess from "assets/img/ic_success_tick.svg"


export const Covid19TestForm = ({ hash, data, priceLabel }) => {
    const cookies = parseCookies()
    const authTokenNew = parseCookies().authToken ? `Bearer ${parseCookies().authToken}` : null
    const streamworx_config = { context: { clientName: 'streamworx', headers: { authorization: authTokenNew, }, } }

    const [manualPayment] = useMutation(manualPaymentQuery, streamworx_config)
    const [newTestPCR] = useMutation(newTestPCRQuery, streamworx_config)

    const { data: provincesData } = useQuery(getProvincesQuery, streamworx_config);
    const { data: getHealthPCR } = useQuery(getHealthPCRQuery, { variables: { source: "HEALTH" }, ...streamworx_config });

    const jamOptions = [
        { value: '09.00 - 10.00', label: '09.00 - 10.00' },
        { value: '10.00 - 11.00', label: '10.00 - 11.00' },
        { value: '11.00 - 12.00', label: '11.00 - 12.00' },
        { value: '12.00 - 13.00', label: '12.00 - 13.00' },
        { value: '13.00 - 14.00', label: '13.00 - 14.00' },
        { value: '14.00 - 15.00', label: '14.00 - 15.00' },
        { value: '15.00 - 16.00', label: '15.00 - 16.00' },
        { value: '16.00 - 17.00', label: '16.00 - 17.00' },
    ]

    const price = {
        label: "Harga",
        information: {
            icon: {
                sourceUrl: null
            },
            text: priceLabel.label,
            button: {
                url: "",
                text: "Pesan"
            },
        },

    }

    const phoneRegex = /^(^\+62\s?|^0)(\d{3,4}-?){2}\d{3,4}$/

    const [provincesOptions, setProvincesOptions] = useState()
    const [citiesOptions, setCitiesOptions] = useState()
    const [PCRData, setPCRData] = useState()
    const [showModal, setShowModal] = useState("")
    const [testDate, setTestDate] = useState(new Date());
    const [pilihJam, setPilihJam] = useState(jamOptions[0])
    const [pilihProvinsi, setPilihProvinsi] = useState("")
    const [pilihKota, setPilihKota] = useState("")
    const [totalPrice, setTotalPrice] = useState(0)



    const handlePlus = (catalogId) => {
        // let newPrice = 0
        let newData = []
        PCRData.forEach(item => {
            if (catalogId === item.catalogId) {
                let dataPlus = {
                    catalogId: item.catalogId,
                    quantity: item.quantity + 1,
                    price: item.price
                }
                newData.push(dataPlus)
            } else {
                newData.push(item)
            }
            // newPrice = newPrice + (item.quantity * item.price)
        });
        // console.log("newPrice", newPrice)
        // setTotalPrice(newPrice)
        setPCRData(newData)
    }

    const handleMinus = (catalogId) => {
        // let newPrice = 0
        let newData = []
        PCRData.forEach(item => {
            if (catalogId === item.catalogId) {
                let dataPlus = {
                    catalogId: item.catalogId,
                    quantity: item.quantity - 1,
                    price: item.price
                }
                newData.push(dataPlus)
            } else {
                newData.push(item)
            }

            // newPrice = newPrice + (item.quantity * item.price)
        });
        // console.log("newPrice", newPrice)
        // setTotalPrice(newPrice)
        setPCRData(newData)
    }

    const initiaValues = {
        waPhoneNumber: "",
        email: "",
        address: "",

    }

    const YupRegisterValidation = Yup.object({
        waPhoneNumber: Yup.string()
            .required("Masukkan no. HP anda")
            .matches(phoneRegex, "No. HP tidak valid"),
        email: Yup.string()
            .email("Format email salah")
            .required("Masukkan email anda"),
        address: Yup.string()
            .required("Masukkan alamat anda")
    })


    const handleSubmit = (values) => {
        const authToken = cookies.authToken

        const date = moment(testDate).format("YYYY-MM-DD")


        if (authToken) {
            //nanti fungsi mutation here
            manualPayment({
                variables: { item: PCRData }
            }).then(res => {
                const referenceId = res.data.manualPayment.referenceId
                const show = {
                    ...values,
                    referenceId,
                    testDate: date,
                    testTime: pilihJam.value,
                    province: pilihProvinsi.value,
                    city: pilihKota.value,
                }
                newTestPCR({
                    variables: { ...show }
                }).then(res2 => {
                    const status = res2.data.newTestPCR.status
                    if (status === "SUCCESS") {
                        navigate("/covid-19-test#success")
                    } else {
                        console.log("terjadi error")
                    }
                }).catch(err2 => console.log("err2", err2))

            }).catch(err => console.log("err", err))
        } else {
            console.log("belum login handle")
            // setCookie(null, 'covid19TestForm', show)
            // navigate("/login")
        }

    }

    const onChangeProvince = (id, value) => {
        setPilihProvinsi(value)
        // setPilihKota()
    }

    const closeModal = () => {
        setShowModal("")
        navigate("/covid-19-test")
    }

    useEffect(() => {
        (async () => {
            const provincesList = await provincesData
            if (provincesList) {
                const listData = [
                    ...provincesList.getProvinces.map((province) => ({ value: province.provinceName, label: province.provinceName, regencies: province.regencies }))
                ]
                setProvincesOptions(listData)
                setPilihProvinsi(listData[0])
            }
        })()

    }, [provincesData])

    useEffect(() => {
        (async () => {
            const province = await pilihProvinsi
            if (province) {
                const listCities = [
                    ...province.regencies.map((regency) => ({ value: regency.regencyName, label: regency.regencyName }))
                ]
                setCitiesOptions(listCities)
                setPilihKota(listCities[0])

            }
        })()

    }, [pilihProvinsi])

    useEffect(() => {
        (async () => {
            const PCRDataNew = await getHealthPCR
            if (PCRDataNew) {
                const listData = [
                    ...getHealthPCR.getHealthPCR.map((data) => ({ catalogId: data.catalogId, quantity: 0, price: data.price }))
                ]
                setPCRData(listData)
            }
        })()

    }, [getHealthPCR])

    useEffect(() => {
        if (hash === "#success") {
            setShowModal("formSuccess")
        }
    }, [hash])

    useEffect(() => {
        let newTotal = 0
        if (PCRData) {
            PCRData?.forEach(item => {
                const quantity = item.quantity
                const price = item.price
                newTotal = newTotal + (price * quantity)
            });
            setTotalPrice(newTotal)
        }
    }, [PCRData])

    return (
        <>
            <Formik
                onSubmit={handleSubmit}
                initialValues={initiaValues}
                validationSchema={YupRegisterValidation}
            >
                {({ handleChange,
                    errors,
                    touched,
                    isSubmitting,
                }) => (
                    <Form>
                        <section className="py-main sc-covid19Test-form">
                            <div className="container mw-lg form-wrapper shadow">
                                <h1 className="font-weight-normal">{data.title}</h1>
                                <p>{data.text}</p>
                                {data.button && (
                                    <Button variant="link" type="button" link={data?.button?.url}>
                                        {data?.button?.text}
                                    </Button>
                                )}
                                <hr className="py-2" />
                                <p>{data.formDescription}</p>
                                <p className="mt-4 caption">Produk yang dipilih</p>
                                {getHealthPCR && getHealthPCR?.getHealthPCR?.map((item, i) => {
                                    const count = PCRData && PCRData.find(x => x.catalogId === item.catalogId)
                                    return (
                                        <div className="container py-3 card-product shadow mb-4" id={item.catalogId} key={i}>
                                            <div className="product-info">
                                                <h5>{item.text}</h5>
                                                <p className="text-muted">Rp{NumFormat(item.price)} x {count?.quantity} = Rp{NumFormat(item.price * count?.quantity)}</p>
                                            </div>
                                            <div className="product-function">
                                                <Button variant="round" onClick={() => handleMinus(item.catalogId)} disabled={count?.quantity <= 0} type="button">-</Button>
                                                <h2 className="mb-0 mx-2 mx-md-4 font-weight-normal">{count?.quantity}</h2>
                                                <Button variant="round" onClick={() => handlePlus(item.catalogId)} type="button">+</Button>
                                            </div>
                                        </div>
                                    )
                                })}
                                <p className="caption">Waktu Tes</p>
                                <div className="form-row row-3">
                                    <div className="form-group col-md-6">
                                        <div className="field field-underline">
                                            <label htmlFor="date" >
                                                Tanggal Tes
                                            </label>
                                            <div className="form-control-wrapper form-date">
                                                <DatePicker
                                                    selected={testDate}
                                                    onChange={(date) => setTestDate(date)}
                                                    name="date"
                                                    placeholder="Tanggal Tes"
                                                    className="date-border"
                                                    touched={touched}
                                                    errors={errors}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <Select
                                            variant="underline"
                                            id="pilih-jam"
                                            name="pilih-jam"
                                            label="Pilih Jam"
                                            options={jamOptions}
                                            onChange={(id, value) => setPilihJam(value)}
                                        />
                                    </div>
                                </div>
                                <p className="caption">Kontak</p>
                                <div className="form-row row-3">
                                    <div className="form-group col-md-6">
                                        <Input
                                            floatingLabel
                                            variant={"underline"}
                                            label={"No. Whatsapp yang Dapat Dihubungi"}
                                            placeholder="masukkan No. Whatsapp"
                                            // inputClassName={inputClassName}
                                            as="input"
                                            id="waPhoneNumber"
                                            name="waPhoneNumber"
                                            // value={values["wa"]}
                                            onChange={handleChange}
                                            touched={touched}
                                            errors={errors}
                                        />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <Input
                                            floatingLabel
                                            variant={"underline"}
                                            label={"Email"}
                                            // inputClassName={inputClassName}
                                            as="input"
                                            type="email"
                                            id="email"
                                            name="email"
                                            // value={values["email"]}
                                            onChange={handleChange}
                                            touched={touched}
                                            errors={errors}
                                        />
                                    </div>
                                </div>
                                <p className="caption">Lokasi Tes</p>
                                <div className="form-row row-3">
                                    <div className="form-group col-md-6">
                                        <Select
                                            variant="underline"
                                            id="provinsi"
                                            name="provinsi"
                                            label="Provinsi"
                                            value={pilihProvinsi}
                                            options={provincesOptions}
                                            onChange={(id, value) => onChangeProvince(id, value)}
                                        />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <Select
                                            variant="underline"
                                            value={pilihKota}
                                            id="kota"
                                            name="kota"
                                            label="Kota"
                                            options={citiesOptions}
                                            onChange={(id, value) => setPilihKota(value)}
                                        />
                                    </div>
                                    <div className="form-group col-12">
                                        <Input
                                            variant={"underline"}
                                            label={"Alamat Tempat Tinggal"}
                                            // inputClassName={inputClassName}
                                            as="input"
                                            id="address"
                                            name="address"
                                            // value={values["email"]}
                                            onChange={handleChange}
                                            touched={touched}
                                            errors={errors}
                                        />
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Covid19Submit data={price} price={totalPrice} />
                    </Form>
                )}
            </Formik>
            <Modal
                id="formSuccess"
                title="Permohonan Terkirim"
                text="Permohonan kamu untuk melakukan tes COVID-19 telah terkirim. Selanjutnya, tim kami akan menghubungi kamu setelah stok terkonfirmasi."
                isShowing={showModal}
                img={icSuccess}
                imgClassName="mb-4"
                imgAlt="IcSuccess"
                className="modal-md bayar-tagihan-modal"
                titleClassName="text-center"
                textClassName="text-center"
            >
                <div className="bayar-tagihan-modal-content mt-5 animated fadeInDown delayp6">
                    <div className="modal-container">
                        <Button variant="primary" className="btn-block" onClick={() => closeModal()} >
                            OK
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    )
}
